const BASE_URL = process.env.VUE_APP_API_BACKEND_URL;
const VERSION = ""

const API = {
    baseUrl : BASE_URL,
    getPatients : {
        name: "getPatients",
        path: () => {return BASE_URL+VERSION+"/patients/"}
    },
    getPatientsByStore: {
        name: "getPatientsByStore",
        path: (id) => { return BASE_URL+VERSION+"/patients/store/"+id+"/"}
    },
    getPatientsLite : {
        name: "getPatientsLite",
        path: ()=> {return BASE_URL+VERSION+"/patients/list/"}
    },
    getPatient : {
        name: "getPatient",
        path: (id) => {return BASE_URL+VERSION+"/patient/"+id+"/"}
    },
    updatePatient : {
        name: "updatePatient",
        path: (id) => {return BASE_URL+VERSION+"/patient/"+id+"/"}
    },
    deletePatient : {
        name: "deletetPatient",
        path: (id) => {return BASE_URL+VERSION+"/patient/"+id+"/"}
    },
    postPatient : {
        name: "postPatient",
        path: () => {return BASE_URL+VERSION+"/patient/"}
    },
    addPatientSale : {
        name: "addPatientSale",
        path: (id)=> {return BASE_URL+VERSION+"/patient/"+id+"/sale/"}
    },
    getPatientMedicalRecord : {
        name: "getPatientMedicalRecord",
        path: (id)=> {return BASE_URL+VERSION+"/patient/"+id+"/medicalRecord/"}
    },
    updatePatientMedicalRecord : {
        name: "updatePatientMedicalRecord",
        path: (id)=> {return BASE_URL+VERSION+"/patient/"+id+"/medicalRecord/"}
    },
    //----------------------------Doctor----------------------------------------------//
    getDoctors : {
        name: "getDoctors",
        path: ()=> {return BASE_URL+VERSION+"/doctors/"}
    },
    getDoctorsByStore: {
        name: "getDoctorsByStore",
        path: (id) => { return BASE_URL+VERSION+"/doctors/store/"+id+"/"}
    },
    getDoctor : {
        name: "getDoctor",
        path: (id)=> {return BASE_URL+VERSION+"/doctor/"+id+"/"}
    },
    updateDoctor : {
        name: "updateDoctor",
        path: (id)=> {return BASE_URL+VERSION+"/doctor/"+id+"/"}
    },
    deleteDoctor : {
        name: "deleteDoctor",
        path: (id)=> {return BASE_URL+VERSION+"/doctor/"+id+"/"}
    },
    postDoctor : {
        name: "postDoctor",
        path: ()=> {return BASE_URL+VERSION+"/doctor/"}
    },
    updateDoctorPsw : {
        name: "updateDoctorPsw",
        path: (id)=>{return BASE_URL+VERSION+"/doctor/"+id+"/changePsw/"}
    },
    //----------------------------MedicalRecord----------------------------------------------//

    getMedicalRecord : {
        name: "getMedicalRecord",
        path: (id)=> {return BASE_URL+VERSION+"/medicalRecord/"+id+"/"}
    },
    updateMedicalRecord : {
        name: "updateMedicalRecord",
        path: (id)=> {return BASE_URL+VERSION+"/medicalRecord/"+id+"/"}
    },
    deleteMedicalRecord : {
        name: "deleteMedicalRecord",
        path: (id)=> {return BASE_URL+VERSION+"/medicalRecord/"+id+"/"}
    },
    postMedicalRecord : {
        name: "postMedicalRecord",
        path: ()=> {return BASE_URL+VERSION+"/medicalRecord/"}
    },
    //----------------------------Games----------------------------------------------//
    getGames : {
        name: "getGames",
        path: ()=> {return BASE_URL+VERSION+"/games/"}
    },
    getGame : {
        name: "getGame",
        path: (id)=> {return BASE_URL+VERSION+"/game/"+id+"/"}
    },
    //----------------------------Sequence----------------------------------------------//
    getSequences : {
        name: "getSequences",
        path: ()=> {return BASE_URL+VERSION+"/sequences/"}
    },
    getSequence : {
        name: "getSequence",
        path: (id)=> {return BASE_URL+VERSION+"/sequence/"+id+"/"}
    },
    //----------------------------PlayActions----------------------------------------------//
    getPlayActions : {
        name: "getPlayActions",
        path: ()=> {return BASE_URL+VERSION+"/playActions/"}
    },
    getPlayAction : {
        name: "getPlayAction",
        path: (id)=> {return BASE_URL+VERSION+"/playAction/"+id+"/"}
    },
    getPatientPlayAction : {
        name: "getPatientPlayAction",
        path: (id)=> {return BASE_URL+VERSION+"/playActions/patient/"+id+"/"}
    },
    getSequencePlayAction : {
        name: "getSequencePlayAction",
        path: (id)=> {return BASE_URL+VERSION+"/playActions/sequence/"+id+"/"}
    },
    getDoctorPlayAction : {
        name: "getDoctorPlayAction",
        path: (id)=> {return BASE_URL+VERSION+"/playActions/doctor/"+id+"/"}
    },
    //----------------------------gamesReport----------------------------------------------//
    getGamesReports : {
        name: "getGamesReports",
        path: ()=> {return BASE_URL+VERSION+"/gamesReports/"}
    },
    getGameGamesReport : {
        name: "getGameGamesReport",
        path: (id)=> {return BASE_URL+VERSION+"/gamesReports/game/"+id+"/"}
    },    
    
    //----------------------------gamesReport----------------------------------------------//

    getAppointments : {
        name: "getAppointments",
        path: ()=> {return BASE_URL+VERSION+"/appointments/"}
    },

    //-------------------------- auth ------------------------------------------------------//
    login: {
        name:'login',
        path: () => {return BASE_URL+VERSION+"/auth/jwt/create/"},
    },
    resetPassword: {
        name: 'reset-password',
        path: () => {return BASE_URL+VERSION+"/auth/users/reset_password/"},
    },
    resetPasswordConfirm: {
        name: 'reset-password-confirm',
        path: () => {return BASE_URL+VERSION+"/auth/users/reset_password_confirm/"},
    },
    activateUser: {
        name: 'activate-user',
        path: () => {return BASE_URL+VERSION+"/auth/users/activation/"},
    },
    getUser: {
        name: 'get-user',
        path: () => { return BASE_URL+VERSION+"/auth/users/me/"}
    },
    refreshToken: {
        name: 'refresh-token',
        path: () => { return BASE_URL+VERSION+"/auth/jwt/refresh/"}
    },
    resendActivation: {
        name: 'resend-activation',
        path: () => { return BASE_URL+VERSION+"/auth/users/resend_activation/"},
    },
    //--------------------------- Stores -------------------------------------------------//
    getStores: {
        name: 'storesList',
        path: () => { return BASE_URL+VERSION+"/stores/" }
    },

    getStore: {
        name: 'storeDetail',
        path: (id) => { return BASE_URL+VERSION+"/stores/"+id+"/" }
    },

    getLicenses: {
        name: 'licensesList',
        path: () => { return BASE_URL+VERSION+"/licenses/" },
    },

    insertStore: {
        name: 'insertStore',
        path: () => { return BASE_URL+VERSION+"/stores/" },
    },

    acceptLicense: {
        name: 'acceptStoreLicense',
        path: (id) => { return BASE_URL+VERSION+"/stores/"+id+"/accept-license/" }
    },

    acceptPrivacy: {
        name: 'acceptStorePrivacy',
        path: (id) => { return BASE_URL+VERSION+"/stores/"+id+"/accept-privacy/" }
    },

    getPatientsCount: {
        name: 'getPatientsCount',
        path: (id) => { return BASE_URL+VERSION+"/stores/"+id+"/statistics/patients/" }
    },

    getSingleExercisesCount: {
        name: 'getSingleExercisesCount',
        path: (id) => { return BASE_URL+VERSION+"/stores/"+id+"/statistics/patients/play-actions-single/"}
    },

    getSequencesCount: {
        name: 'getSequencesCount',
        path: (id) => { return BASE_URL+VERSION+"/stores/"+id+"/statistics/patients/play-actions-sequence/"}     
    }



    
};

export default API;

