import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import router from './router'

import vco from "v-click-outside"
import VueApexCharts from 'vue-apexcharts'
import VueSweetalert2 from 'vue-sweetalert2';
import VueSlideBar from 'vue-slide-bar'
import Vuelidate from 'vuelidate'
// import i18n from './i18n'
import store from '@/state/store'

import App from './App.vue'
// As a plugin
import VueMask from 'v-mask'
Vue.config.productionTip = false

import * as VueGoogleMaps from "vue2-google-maps";
import Lightbox from 'vue-easy-lightbox'

//axios
import axios from 'axios'
import VueAxios from 'vue-axios'
import API from '@/api.js';
import VueAuth, { DEFAULT_OPTIONS } from '@d0whc3r/vue-auth-plugin';

const authOptions = {
  ...DEFAULT_OPTIONS,
  // tokenStore: 'localStorage',
  rolesVar: 'role',
  tokenType: 'Bearer',
  authRedirect: '/login',
  loginData: {
    url : API.login.path(),
    method: 'POST',
    redirect: '/auth',
    headerToken: 'Authorization',
    fetchUser: true,
    customToken: function(response) {
      return response.data.access;
    },
  },
  logoutData: {
    url: null,
    redirect: '/login',
    makeRequest: false,
  },
  fetchItem: '',
  fetchData: {
    url: API.getUser.path(),
    method: 'GET',
    interval: 5,
    enabled: true,
  },
  // refreshData: {
  //   url: API.refreshToken.path(),
  //   method: 'POST',
  //   enabled: true,
  //   interval: 1,
  // },
};

Vue.use(VueAxios, axios)
Vue.use(require('vue-faker'));
Vue.use(Lightbox)
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    libraries: "places"
  },
  installComponents: true
});
Vue.use(VueAuth, authOptions);

// import { initFirebaseBackend } from './helpers/firebase/authUtils';

// import { configureFakeBackend } from './helpers/fakebackend/fake-backend';

// const firebaseConfig = {
//   apiKey: process.env.VUE_APP_APIKEY,
//   authDomain: process.env.VUE_APP_AUTHDOMAIN,
//   databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
//   projectId: process.env.VUE_APP_PROJECTId,
//   storageBucket: process.env.VUE_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
//   appId: process.env.VUE_APP_APPId,
//   measurementId: process.env.VUE_APP_MEASUREMENTID
// };

// if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
//   initFirebaseBackend(firebaseConfig);
// } else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
//   configureFakeBackend();
// }

import '@/assets/scss/app.scss'

Vue.component('VueSlideBar', VueSlideBar)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.component('apexchart', VueApexCharts)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);

Vue.use(require("vue-chartist"));
Vue.use(VueMask);

new Vue({
  router,
  store,
  // i18n,
  render: h => h(App),
}).$mount('#app')
